import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useCountryContext } from '../../../contexts/countryContext'

import LocationAutocompleteField from '../../molecules/LocationAutocompleteField'
import ClearButton from '../../atoms/ClearButton'

import Close from './close.svg'

import styles from './styles.module.scss'

const LocationMenuMobile = ({
  id,
  isOpen,
  onClose,
  initialLocation,
  handleSelectLocation,
  handleSubmitLocation,
  handleChangeLocation,
  autocompleteApi,
  countryCodeForAutocomplete,
  handleSetCountry,
  showGeoCoordinatesError,
  clearGeoCoordinatesError,
}) => {
  const [location, setLocation] = useState(initialLocation)

  const handleClose = () => {
    setLocation(initialLocation)
    if (handleChangeLocation) {
      handleChangeLocation(initialLocation)
    }

    onClose()
  }

  const handleFieldChange = (location) => {
    setLocation(location)
    if (handleChangeLocation) {
      handleChangeLocation(location)
    }
  }

  return (
    <div
      className={classNames(styles.menuWrapper, {
        [styles.menuWrapperVisible]: isOpen,
      })}
    >
      <div
        id={id}
        className={classNames(styles.menu, { [styles.menuOpen]: isOpen })}
        aria-hidden={!isOpen}
        data-testid={id}
      >
        <div className={styles.header}>
          <strong className={styles.heading} data-testid="menu-heading">
            Location
          </strong>
          <button
            className={styles.closeButton}
            onClick={handleClose}
            aria-label="Close menu"
            aria-controls={id}
          >
            <Close className={styles.closeIcon} />
          </button>
        </div>

        {isOpen ? (
          <LocationAutocompleteField
            name="location"
            id="location-mobile"
            initialValue={location}
            handleOnChange={handleFieldChange}
            handleOnSelect={handleSelectLocation}
            handleOnSubmit={handleSubmitLocation}
            showGeoCoordinatesError={showGeoCoordinatesError}
            clearGeoCoordinatesError={clearGeoCoordinatesError}
            autocompleteApi={autocompleteApi}
            countryCodeForAutocomplete={countryCodeForAutocomplete}
            setCountry={handleSetCountry}
            autoFocus
            renderLabel={(props) => (
              <label {...props} className={styles.label}>
                Location
              </label>
            )}
            renderInputWrapper={({ ref, children, ...props }) => (
              <div ref={ref} {...props} className={styles.inputWrapper}>
                {children}
              </div>
            )}
            renderInput={({ ref, ...props }) => (
              <input
                ref={ref}
                {...props}
                className={styles.input}
                placeholder="Search by city or university"
              />
            )}
            renderClearButton={(props) => (
              <ClearButton {...props} className={styles.clearButton} />
            )}
            renderMenuContainer={({ children, ...props }) => (
              <div {...props} className={styles.menuContainer}>
                {children}
                <img
                  className={styles.googleLogo}
                  src="/media/icons/google-logo.png"
                  alt="powered by Google"
                />
              </div>
            )}
          />
        ) : null}
      </div>
    </div>
  )
}

LocationMenuMobile.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialLocation: PropTypes.string.isRequired,
  handleSelectLocation: PropTypes.func.isRequired,
  handleSubmitLocation: PropTypes.func.isRequired,
  handleChangeLocation: PropTypes.func,
  autocompleteApi: PropTypes.func.isRequired,
  countryCodeForAutocomplete: PropTypes.string.isRequired,
  handleSetCountry: PropTypes.func,
  showGeoCoordinatesError: PropTypes.bool.isRequired,
  clearGeoCoordinatesError: PropTypes.func.isRequired,
}

export default LocationMenuMobile
