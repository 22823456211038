import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Picture from '../../atoms/Picture'

import './styles.scss'

const CardWithImage = ({
  className,
  imageSrc,
  imageDataSources,
  imageAlt,
  children,
  ...props
}) => {
  return (
    <article className={classNames('card-with-image', className)} {...props}>
      <div className="card-with-image__image-wrapper">
        <Picture
          sources={imageDataSources}
          src={imageSrc}
          alt={imageAlt}
          lazyload
        />
      </div>
      <div className="card-with-image__content-wrapper">{children}</div>
    </article>
  )
}

CardWithImage.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  imageDataSources: PropTypes.array.isRequired,
  imageAlt: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default CardWithImage
