import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import config from '../../../config'

const MetaTags = ({
  title,
  description,
  imageUrl,
  absoluteUrl,
  ogContentType = 'website',
  robots = 'all',
  canonical = absoluteUrl,
  children,
}) => {
  const placeholderImageUrl = '/media/images/social-placeholder.png'

  const ogImage = imageUrl
    ? `${config.imgApiUrl}w=1200/${imageUrl}`
    : placeholderImageUrl

  const twitterImage = imageUrl
    ? `${config.imgApiUrl}w=1200,h=600,fit=cover/${imageUrl}`
    : placeholderImageUrl

  const twitterCardType = imageUrl ? 'summary_large_image' : 'summary'

  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={canonical} />

      <meta name="description" content={description} />
      <meta name="rating" content="general" />
      <meta name="robots" content={robots} />

      <meta property="og:site_name" content="Accommodation for Students" />
      <meta property="og:type" content={ogContentType} />
      <meta property="og:url" content={absoluteUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      <meta property="twitter:card" content={twitterCardType} />
      <meta property="twitter:site" content="@accomforstudent" />
      <meta property="twitter:url" content={absoluteUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={twitterImage} />

      <meta property="snapchat:sticker" content={ogImage} />

      {children}
    </Head>
  )
}

MetaTags.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  absoluteUrl: PropTypes.string.isRequired,
  ogContentType: PropTypes.string,
  robots: PropTypes.string,
  canonical: PropTypes.string,
  children: PropTypes.node,
}

export default MetaTags
