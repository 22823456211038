import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Heading from '@afs/components/Heading'

import config from '../../../config'
import { useCountryContext } from '../../../contexts/countryContext'
import useMedia from '../../../hooks/useMedia'
import useIntercomMessenger from '../../../hooks/useIntercomMessenger'

import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'
import LinkButton from '../../atoms/LinkButton'

import Phone from './svgs/phone.svg'
import Email from './svgs/email.svg'
import Chat from './svgs/chat.svg'

import styles from './styles.module.scss'

const image =
  'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/support-section/support.png'
const mobileImage =
  'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/support-section/support-mobile.png'
const fallbackImage = `${config.imgApiUrl}w=737/${image}`

const sources = [
  {
    id: 'desktop',
    srcSet: `${config.imgApiUrl}w=737/${image} 1x, ${config.imgApiUrl}w=737,dpr=2/${image} 2x`,
    media: '(min-width: 901px)',
  },
  {
    id: 'tablet',
    srcSet: `${config.imgApiUrl}w=608,h=404,fit=cover,gravity=top/${mobileImage} 1x, ${config.imgApiUrl}w=608,h=404,fit=cover,gravity=top,dpr=2/${mobileImage} 2x`,
    media: '(min-width: 481px) and (max-width: 900px)',
  },
  {
    id: 'mobile',
    srcSet: `${config.imgApiUrl}w=480,h=319,fit=cover,gravity=top/${mobileImage} 1x, ${config.imgApiUrl}w=480,h=319,fit=cover,gravity=top,dpr=2/${mobileImage} 2x`,
    media: '(max-width: 480px)',
  },
]

const SupportSection = ({ className }) => {
  const { supportPhoneNumber } = useCountryContext()

  const hideLongEmailAddress = useMedia(['(max-width: 1124px)'], [true], false)

  const { openMessenger } = useIntercomMessenger()

  const onLiveChatButtonClick = () => {
    openMessenger()
  }

  return (
    <section
      className={classNames(styles.section, className)}
      data-testid="support-section"
    >
      <div className={styles.inner}>
        <Picture
          className={styles.image}
          lazyload
          src={fallbackImage}
          sources={sources}
          alt="A woman wearing a headset is smiling at the camera"
        />

        <div className={styles.contactBox}>
          <div className={styles.contactBoxInner}>
            <Heading className={styles.heading} level={2}>
              We’re here to help
            </Heading>
            <p className={styles.text}>
              Our friendly team of experts are always on hand. If you have a
              question or need help finding accommodation, get in touch.
            </p>
            <ul className={styles.contactList}>
              <li>
                <Phone className={styles.contactIcon} />
                Call weekdays on{' '}
                <Link
                  className={styles.contactLink}
                  href={`tel:${supportPhoneNumber.withCallingCode}`}
                  target="_blank"
                >
                  {supportPhoneNumber.display}
                </Link>
              </li>
              <li>
                <Email className={styles.contactIcon} />
                <Link
                  className={styles.contactLink}
                  href="mailto:support@accommodationforstudents.com"
                  target="_blank"
                >
                  {hideLongEmailAddress
                    ? 'Email us'
                    : 'support@accommodationforstudents.com'}
                </Link>
              </li>
              <li>
                <Chat className={styles.contactIcon} />
                <LinkButton
                  className={styles.contactLink}
                  onClick={onLiveChatButtonClick}
                >
                  Start a conversation
                </LinkButton>
                &nbsp;on live chat
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

SupportSection.propTypes = {
  className: PropTypes.string,
}

export default SupportSection
