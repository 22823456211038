import React from 'react'
import PropTypes from 'prop-types'

import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'

import config from '../../../config'

import styles from './styles.module.scss'

const FeaturedCityCard = ({ city, shouldDisplayPropertyCount }) => {
  const fallbackImageSource = `${config.imgApiUrl}w=276,h=160,fit=cover/${city.imageUrl}`

  const sources = [
    {
      id: 'desktop',
      srcSet: `${config.imgApiUrl}w=276,h=160,fit=cover/${city.imageUrl} 1x, ${config.imgApiUrl}w=276,h=160,fit=cover,dpr=2/${city.imageUrl} 2x`,
      media: '(min-width: 769px)',
    },
    {
      id: 'tablet',
      srcSet: `${config.imgApiUrl}w=320,h=184,fit=cover/${city.imageUrl} 1x, ${config.imgApiUrl}w=320,h=184,fit=cover,dpr=2/${city.imageUrl} 2x,`,
      media: '(min-width: 661px) and (max-width: 768px)',
    },
    {
      id: 'mobile',
      srcSet: `${config.imgApiUrl}w=276,h=160,fit=cover/${city.imageUrl} 1x, ${config.imgApiUrl}w=276,h=160,fit=cover,dpr=2/${city.imageUrl} 2x`,
      media: '(max-width: 660px)',
    },
  ]

  return (
    <Link className={styles.link} href={`${config.baseUrl}/${city.linkPath}`}>
      <article>
        <Picture
          className={styles.image}
          lazyload
          src={fallbackImageSource}
          sources={sources}
          alt={`Photo of the city ${city.name}`}
        />
        <strong className={styles.label}>{city.name}</strong>
      </article>
      {shouldDisplayPropertyCount ? (
        <span className={styles.propertyCount}>
          {city.propertyCount} properties
        </span>
      ) : null}
    </Link>
  )
}

FeaturedCityCard.propTypes = {
  city: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    linkPath: PropTypes.string.isRequired,
    propertyCount: PropTypes.number,
  }),
}

export default FeaturedCityCard
