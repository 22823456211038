import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LocationMenuMobile from '../../LocationMenuMobile'

import SearchIcon from './svgs/search-icon-mobile.svg'
import LoadingIcon from './svgs/loading-icon-mobile.svg'

import styles from './styles.module.scss'

const HomePageSearchBarMobile = ({
  className,
  selectedLocation,
  handleSelectLocation,
  handleSubmitLocation,
  handleChangeLocation,
  autocompleteApi,
  countryCodeForAutocomplete,
  clearGeoCoordinatesError,
  showGeoCoordinatesError,
  isLoading,
  isRedirecting,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleMenuOpen = () => {
    setIsOpen(true)
  }

  const handleMenuClose = () => {
    setIsOpen(false)
  }

  return (
    <div className={className}>
      <button
        className={classNames(styles.button, {
          [styles.buttonLoading]: isLoading,
        })}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="location-mobile"
        data-testid="search-mobile"
        onClick={handleMenuOpen}
        disabled={isLoading}
        aria-disabled={isLoading}
      >
        <SearchIcon className={styles.searchIcon} />

        <span
          className={classNames(styles.buttonText, {
            [styles.buttonTextLoading]: selectedLocation,
          })}
        >
          {selectedLocation || 'Search by city or university'}
        </span>

        {isRedirecting ? (
          <LoadingIcon
            className={styles.loadingIcon}
            data-testid="loading-icon"
          />
        ) : null}
      </button>
      <LocationMenuMobile
        id="location-mobile"
        onClose={handleMenuClose}
        isOpen={isOpen}
        initialLocation={selectedLocation}
        handleSubmitLocation={(selection) => {
          handleSubmitLocation(selection, handleMenuClose)
        }}
        handleSelectLocation={handleSelectLocation}
        handleChangeLocation={handleChangeLocation}
        autocompleteApi={autocompleteApi}
        countryCodeForAutocomplete={countryCodeForAutocomplete}
        clearGeoCoordinatesError={clearGeoCoordinatesError}
        showGeoCoordinatesError={showGeoCoordinatesError}
      />
    </div>
  )
}

HomePageSearchBarMobile.propTypes = {
  className: PropTypes.string,
  selectedLocation: PropTypes.string.isRequired,
  handleSelectLocation: PropTypes.func.isRequired,
  handleSubmitLocation: PropTypes.func.isRequired,
  handleChangeLocation: PropTypes.func.isRequired,
  autocompleteApi: PropTypes.func.isRequired,
  countryCodeForAutocomplete: PropTypes.oneOf([
    'afsCountries',
    'gb',
    'ie',
    'au',
  ]).isRequired,
  clearGeoCoordinatesError: PropTypes.func.isRequired,
  showGeoCoordinatesError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRedirecting: PropTypes.bool.isRequired,
}

export default HomePageSearchBarMobile
