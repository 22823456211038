export const propertyType = {
  any: 'any',
  house: 'house',
  flat: 'flat',
  studio: 'studio',
  halls: 'halls',
}

export const pluralisedPropertyTypeFrom = (type) => {
  return type === propertyType.halls ? type : `${type}s`
}
