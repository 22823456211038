import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Heading from '@afs/components/Heading'

import config from '../../../config'

import Link from '../../atoms/Link'
import CardWithImage from '../CardWithImage'

import styles from './styles.module.scss'

const GuideCard = ({ className, guide, homePageVariant }) => {
  const { imgApiUrl } = config

  const fallbackImageUrl = `${imgApiUrl}w=456,h=456,fit=cover/${guide.imageUrl}`
  const imageSources = [
    {
      id: 'desktop',
      srcSet: `${imgApiUrl}w=320,h=320,fit=cover/${guide.imageUrl} 1x, ${imgApiUrl}w=320,h=320,dpr=2,fit=cover/${guide.imageUrl} 2x`,
      media: '(min-width: 481px)',
    },
    {
      id: 'mobile',
      srcSet: `${imgApiUrl}w=456,h=156,fit=cover/${guide.imageUrl} 1x, ${imgApiUrl}w=456,h=156,dpr=2,fit=cover/${guide.imageUrl} 2x`,
      media: '(max-width: 480px)',
    },
  ]

  return (
    <Link
      className={classNames(styles.card, className)}
      key={guide.title}
      to={guide.link}
      data-testid="guide-card"
    >
      <CardWithImage
        className={styles.inner}
        imageSrc={fallbackImageUrl}
        imageDataSources={imageSources}
        imageAlt={guide.title}
      >
        <Heading
          className={classNames(styles.heading, {
            [styles.headingHomepageVariant]: homePageVariant,
          })}
          level={4}
        >
          {guide.title}
        </Heading>
        <p
          className={classNames(styles.introduction, {
            [styles.introductionHomePageVariant]: homePageVariant,
          })}
        >
          {guide.description}
        </p>
        <div className={styles.fauxLinkWrapper}>
          <p
            className={classNames(styles.fauxLink, {
              [styles.fauxLinkHomePageVariant]: homePageVariant,
            })}
          >
            {guide.linkText || 'Read more'}
          </p>
          <span className={styles.fauxLinkArrow}>{'->'}</span>
        </div>
      </CardWithImage>
    </Link>
  )
}

GuideCard.propTypes = {
  className: PropTypes.string,
  guide: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string,
  }).isRequired,
  homePageVariant: PropTypes.bool,
}

export default GuideCard
