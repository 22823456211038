import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FeaturedCityCard from '../../molecules/FeaturedCityCard'

import styles from './styles.module.scss'

const FeaturedCities = ({
  className,
  featuredCities,
  shouldDisplayPropertyCount,
}) => {
  return (
    <div
      className={classNames(styles.grid, className)}
      data-testid="featured-cities"
    >
      {featuredCities.map((cityToFeature) => (
        <FeaturedCityCard
          key={cityToFeature.name}
          city={cityToFeature}
          shouldDisplayPropertyCount={shouldDisplayPropertyCount}
        />
      ))}
    </div>
  )
}

FeaturedCities.propTypes = {
  className: PropTypes.string.isRequired,
  featuredCities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      linkPath: PropTypes.string.isRequired,
      propertyCount: PropTypes.number,
    })
  ),
}

export default FeaturedCities
